import {renderPagination, exportToCsv, arrayToString} from '../packs/application.js';
import {sorting} from '../orders_list/index'

$( document ).on('turbolinks:load', function() {
  const url = window.location.href;
  if (url.match(/pending_prepaid_purchased_orders($|[#?])/) ){
    $('.pending_prepaid_purchased_orders').addClass('active')
    $('select').formSelect();

    $('#filter-modal').modal({onCloseEnd: () => {
      $("#open_orders_filter").prop( "checked", $('#open_orders_filter_value').attr('data-checked') === 'true' )
      $("#pending_orders_filter").prop( "checked",  $('#pending_orders_filter_value').attr('data-checked') === 'true' )
    } });

    $('#select-columns-modal').modal({onCloseEnd: () => {
      $('.column-values .hidden').each((index, hidden_value) => {
        let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
        $(`#${checkbox_id}`).prop( "checked", $(hidden_value).attr('data-checked') === 'true')
      })
    } });

    $('#export-modal').modal()  
    
    $('#export-modal-button').on('click', () => {
      renderExportFilters()
      $('#export-modal').modal('open')
    })

    $('#export-button').on('click', () => {
      exportToCsv('/export_prepaid_purchase_orders_list', {activeFilters: activePrepaidFilters(),
                                                           selectedColumns: getActiveColumns(),
                                                           sorting: sorting()})
    })

    $('#clear-columns-button').on('click', () => {
      $('.column-values .hidden').each((index, hidden_value) => {
        $(hidden_value).attr('data-checked', false)
        let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
        $(`#${checkbox_id}`).prop('checked', false)
      })
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'prepaid_purchase_orders',
                         activeFilters: activePrepaidFilters(),
                         sorting: sorting(),
                         columnsSelected: []})
      calculateTotals()
      $('#select-columns-modal').modal('close')
    })

    $('#select-columns-button').on('click', () => {
      $('.column-values .hidden').each((index, hidden_value) => {
        let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
        $(hidden_value).attr('data-checked', $(`#${checkbox_id}`).is(':checked'));
      })
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'prepaid_purchase_orders',
                         activeFilters: activePrepaidFilters(),
                         sorting: sorting(),
                         columnsSelected: getActiveColumns()})
      calculateTotals()
      $('#select-columns-modal').modal('close')
    })

    $("#filter-button").on('click', function() {
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'prepaid_purchase_orders',
                         activeFilters: activePrepaidFilters(),
                         sorting: sorting(),
                         columnsSelected: getActiveColumns()})
      calculateTotals()
      $('#open_orders_filter_value').attr('data-checked', $("#open_orders_filter").is(':checked'));
      $('#pending_orders_filter_value').attr('data-checked', $("#pending_orders_filter").is(':checked'));
      $("#filter-modal").modal("close");
    });

    $("#clear-filter-button").on('click', function() {
      $('#open_orders_filter_value').attr('data-checked', false);
      $('#pending_orders_filter_value').attr('data-checked', false);

      $("#open_orders_filter").prop( "checked", false )
      $("#pending_orders_filter").prop( "checked",  false )

      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'prepaid_purchase_orders',
                         activeFilters: [],
                         sorting: sorting(),
                         columnsSelected: getActiveColumns()})
      calculateTotals()
      $("#filter-modal").modal("close");
    });

    $("#orders_per_page_select").on('change', function() {
      renderPagination({numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                        dataSource: 'prepaid_purchase_orders',
                        activeFilters: activePrepaidFilters(),
                        sorting: sorting(),
                        columnsSelected: getActiveColumns()} )
    });

    renderPagination({numberOfPages: 10,
                      dataSource: 'prepaid_purchase_orders',
                      activeFilters: activePrepaidFilters(),
                      sorting: sorting(),
                      columnsSelected: getActiveColumns()})
    calculateTotals()
  }
})

export const renderPaginationPrepaidOrdersParams = () => ({numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                                                           dataSource: 'prepaid_purchase_orders',
                                                           activeFilters: activePrepaidFilters(),
                                                           sorting: sorting()})

const activePrepaidFilters = () => {
  let active_filters = []
  if($("#open_orders_filter").is(':checked')) active_filters.push('open')
  if($("#pending_orders_filter").is(':checked')) active_filters.push('pending')
  return active_filters
}

const getActiveColumns = () => {
  let active_columns = []
  $('.column-values .hidden').each((index, hidden_value) => {
    let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
    if($(`#${checkbox_id}`).is(':checked')) active_columns.push(checkbox_id.replace('_column', ''))
  })
  return active_columns
}

const calculateTotals = () => {
  $('#totals').html(`<tbody>
                      <tr>
                        <td>
                          Loading...
                          <div class="lds-dual-ring"></div>
                        </td>
                      </tr>
                    </tbody>`)
  $.ajax({
    async: true,
    method: 'get',
    url: '/prepaid_purchase_orders_totals',
    data: { filters: activePrepaidFilters(),
            columns: getActiveColumns()},
    success:  (response) => {
      $('#totals').html(response)
    },
  });
}

const renderExportFilters = () => {
  let text = 'The exported file will include all'
  let active_filters = activePrepaidFilters()
  let filters = 'open and pending'
  let numberOfFilters = $('.filter-values .hidden').length
  if(active_filters.length > 0 && active_filters.length < numberOfFilters){
    filters = arrayToString(active_filters)
  }
  $('#filters-for-export').html(`${text} ${filters} prepaid purchase orders`)
}
