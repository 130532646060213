// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
import 'materialize-css/dist/js/materialize'
import '../stylesheets/application'
require("paginationjs");
import '../orders_list/index'
import '../orders_list/reports'
import {renderPaginationSalesOrdersParams} from '../orders_list/index'
import {renderPaginationPrepaidOrdersParams} from '../purchase_orders/prepaid'
import '../purchase_orders/prepaid'
import '../items/inventory'
import '../items/sold_and_ordered'

Rails.start()
Turbolinks.start()

$(document).on("turbolinks:load", () => {
  $(".sidenav").sidenav();
});

$(document).on("turbolinks:before-cache", () => {
  M.Sidenav.getInstance($(".sidenav")).destroy();
});

document.addEventListener('turbolinks:before-cache', () => {
  M.Modal._count = 0;
  $('select').formSelect('destroy');
})

export const renderPagination = (options) => {
  if($('#order_pagination').length > 0){
    $('#order_pagination').html('')
    $('#order_pagination').pagination({
      dataSource: options['dataSource'],
      locator: 'page',
      totalNumberLocator: function(response) {
          return response['totalNumber'];
      },
      pageSize: options['numberOfPages'],
      showGoButton: true,
      showGoInput: true,
      ajax: {
          data: { filters: options['activeFilters'],
                  columns: options['columnsSelected'],
                  dates: options['dates'],
                  sort: options['sorting'],
                  locations: options['activeLocations'] },
          beforeSend: () => {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            $('#loader').show()
          }
      },
      callback: function(data, pagination) {
          $('#data-container').html(data)
          $('#loader').hide()
          if ($('#sorting-created').length > 0){
            $('#sorting-created').on('click', changeSorting)
          }
          $('#export_modal_text').html('Are you sure you want to export the data? This could take a little time.')
          if( options['functionsAfterRender'] ){
            options['functionsAfterRender'].forEach(element => {
              const func = element;
              func();
            });
          }
      },
      alias: { pageNumber: 'page',
               pageSize: 'per_page' }
    })
  }
};

const changeSorting = () => {
  if ($('#sorting-icon').hasClass('asc')){
    $('#sorting-icon').removeClass('asc')
    $('#sorting-icon').addClass('desc')
    $('#sorting-value').attr('data-order', 'desc')
  } else {
    $('#sorting-icon').removeClass('desc')
    $('#sorting-icon').addClass('asc')
    $('#sorting-value').attr('data-order', 'asc')
  }
  let paginationParams;
  const url = window.location.href;
if (url.match(/pending_prepaid_purchased_orders($|[#?])/) ){
    paginationParams = renderPaginationPrepaidOrdersParams()
  } else {
    paginationParams = renderPaginationSalesOrdersParams()
  }
  renderPagination(paginationParams)
}

const generateCSV = (response) => {
  $('#export_modal_text').html('Exported correctly')
  $('#export-button button').removeClass('disabled')
  let a = document.createElement('a');
  let url = window.URL.createObjectURL(response);
  a.href = url;
  a.download = csvTitle();
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

const csvTitle = () => {
  const url = window.location.href;
  let title = ''
  if (url.match(/sales_orders($|[#?])/)) {
    title = 'Sales orders list'
  } else if (url.match(/sales_orders_report($|[#?])/)){
    title = 'Sales orders report'
  } else if (url.match(/pending_prepaid_purchased_orders($|[#?])/) ){
    title = 'Open and pending prepaid purchase orders report'
  } else if (url.match(/inventory_report($|[#?])/)) {
    title = 'Inventory report'
  } else if (url.match(/sold_and_ordered_items_report($|[#?])/)) {
    title = 'Sold and ordered report'
  }
  return `${title}.csv`
}

export const arrayToString = (array) => {
  let length = array.length
  if (length === 1){
    return array.toString()
  } else {
    return `${array.slice(0, -1).join(', ')} and ${array.slice(-1)}`
  }
}

export const exportToCsv = (reportSource, options) => {
  $('#export_modal_text').html(`Exporting...
                    <div class="lds-dual-ring"></div>`)
  $('#export-button button').addClass('disabled')
  $.ajax({
    async: true,
    xhrFields: {
      responseType: 'blob'
    },
    method: 'get',
    url: reportSource,
    data: { columns: options['selectedColumns'],
            filters: options['activeFilters'],
            dates: options['selectedDates'],
            sort: options['sorting'],
            locations: options['activeLocations'],
            report: options['report']},
    success:  (response) => {
      generateCSV(response)
    },
    error: () => {
      M.toast({html: 'An error has occurred, please reload the page.'})
      $('#export_modal_text').html('Error exporting the report')
    }
  });
}
