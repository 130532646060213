import {renderPagination} from '../packs/application.js';
import {selectedColumns} from './index.js'
import {activeFilters} from './index.js'
import {sorting} from './index.js'
import {getActiveLocations} from './index.js'


$( document ).on('turbolinks:load', function() {
  const url = window.location.href;
  if (url.match(/sales_orders_report($|[#?])/) ){
    $('.sales-orders-reports-tab').addClass('active')

    let date = new Date()
    $('#until-date').datepicker({setDefaultDate: true,
                                 defaultDate: date, 
                                 format: 'yyyy-mm-dd',
                                 autoClose: true
                                });
    date.setDate(date.getDate() - 7)
    $('#from-date').datepicker({setDefaultDate: true,
                                defaultDate: date, 
                                format: 'yyyy-mm-dd',
                                autoClose: true
                              });
    calculateReport()

    $("#from-date").on('change', () => {
      calculateReport()
      updateOrders()
    })
    $("#until-date").on('change', () => {
      calculateReport()
      updateOrders()
    })
  }
})


export const selectedDates = () => {
  if($('#from-date').length > 0 && $('#until-date').length > 0){
    return {from: $('#from-date').val(), until: $('#until-date').val()}
  } else {
    return {}
  }
};

const updateOrders = () => {
  renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                     dataSource: 'orders',
                     columnsSelected: selectedColumns(),
                     activeFilters: activeFilters(),
                     dates: selectedDates(),
                     sorting: sorting(),
                     activeLocations: getActiveLocations() })
}

export const calculateReport = () => {
  $('#report').html(`<tbody>
                      <tr>
                        <td>
                          Loading report...
                          <div class="lds-dual-ring"></div>
                        </td>
                      </tr>
                    </tbody>`)
  $.ajax({
    async: true,
    method: 'get',
    url: '/report',
    data: { filters: activeFilters(),
            dates: selectedDates(),
            locations: getActiveLocations()},
    success:  (response) => {
      $('#report').html(response)
    },
  });
}
