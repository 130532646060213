import {exportToCsv, renderPagination} from '../packs/application.js';
import {selectedDates} from '../orders_list/reports'

$( document ).on('turbolinks:load', function() {
  const url = window.location.href;
  if (url.match(/sold_and_ordered_items_report($|[#?])/) ){
    $('.sold-and-ordered-items-tab').addClass('active')

    $('#export-modal').modal()
    $('#quantities-modal').modal()

    $('#export-modal-button').on('click', () => {
      renderExportFilters()
      $('#export-modal').modal('open')
    })

    let date = new Date()
    $('#until-date').datepicker({setDefaultDate: true,
                                 defaultDate: date,
                                 format: 'yyyy-mm-dd',
                                 autoClose: true
                                });
    date.setDate(date.getDate() - 7)
    $('#from-date').datepicker({setDefaultDate: true,
                                defaultDate: date,
                                format: 'yyyy-mm-dd',
                                autoClose: true
                              });

    $('#from-date').on('change', () => {
      calculateReport()
    })
    $('#until-date').on('change', () => {
      calculateReport()
    })

    $('#export-button').on('click', () => {
      exportToCsv('/export_sold_and_ordered_report', { selectedDates: selectedDates() })
    })

    calculateReport()
  }
})

const calculateReport = () => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/sold_and_ordered_report',
    data: { dates: selectedDates() },
    beforeSend: () => {
      $('#loader').show()
    },
    success:  (response) => {
      $('#data-container').html(response)
      $('#loader').hide()
      addActionToModalOpener()
      $('#export_modal_text').html('Are you sure you want to export the data? This could take a little time.')
    },
    error: () => {
      M.toast({html: 'An error has occurred, please reload the page.'})
      $('#data-container').html('')
      $('#loader').hide()
    }
  });
}

const addActionToModalOpener = () => {
  $('.certain-qty-invoiced-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
  $('.certain-qty-sold-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
  $('.po-quantity-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
  $('.inventory-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
  $('.uncertain-qty-invoiced-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
  $('.uncertain-qty-sold-modal').on('click', (event) => {
    renderModal(event.target.id, event.target.getAttribute('data-modal'))
  })
}

const renderExportFilters = () => {
  let dates = ''
  let selected_dates = selectedDates()
  if (Object.keys(selected_dates).length > 0)
    dates = `between <span style="white-space:nowrap">${selected_dates['from']}</span> and
              <span style="white-space:nowrap">${selected_dates['until']}</span>`
  let text = `The exported file will include sold and ordered items ${dates}.`
  $('#filters-for-export').html(`${text}`)
}

const renderModal = (id, type_of_modal) => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/sold_and_ordered_report_modal',
    beforeSend: () => {
      $('#loader').show()
    },
    data: { item_id: id,
            type_of_modal: type_of_modal},
    success:  (response) => {
      $('#quantities-modal').html(response)
      $('#loader').hide()
      $('#quantities-modal').modal('open')
    },
    error: () => {
      M.toast({html: 'An error has occurred.'})
      $('#loader').hide()
    }
  });
}
