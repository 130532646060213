import {renderPagination, arrayToString, exportToCsv} from '../packs/application.js';
import {selectedDates} from './reports.js'
import {calculateReport} from './reports.js'

$( document ).on('turbolinks:load', function() {
  const url = window.location.href;
  if (url.match(/sales_orders($|[#?])/) || url.match(/sales_orders_report($|[#?])/) ){
    $('#filter-modal').modal({onCloseEnd: function() { // Callback for Modal close
      $("#open_orders_filter").prop( "checked", $('#open_orders_filter_value').attr('data-checked') === 'true' )
      $("#pending_orders_filter").prop( "checked",  $('#pending_orders_filter_value').attr('data-checked') === 'true' )
      $("#cancelled_orders_filter").prop( "checked",  $('#cancelled_orders_filter_value').attr('data-checked') === 'true' )
      $("#closed_orders_filter").prop( "checked", $('#closed_orders_filter_value').attr('data-checked') === 'true' )
    } });

    $('#select-columns-modal').modal({onCloseEnd: function() { // Callback for Modal close
      $("#status_column").prop( "checked", $('#status_column_value').attr('data-checked') === 'true' )
      $("#customer_column").prop( "checked", $('#customer_column_value').attr('data-checked') === 'true' )
      $("#customer_column_type").prop( "checked", $('#customer_column_type_value').attr('data-checked') === 'true' )
      $("#total_qty_column").prop( "checked", $('#total_qty_column_value').attr('data-checked') === 'true' )
      $("#invoiced_qty_column").prop( "checked", $('#invoiced_qty_column_value').attr('data-checked') === 'true' )
      $("#shipping_method_column").prop( "checked", $('#shipping_method_column_value').attr('data-checked') === 'true' )
      $("#total_column").prop( "checked", $('#total_column_value').attr('data-checked') === 'true' )
      $("#deposit_amount_column").prop( "checked", $('#deposit_amount_column_value').attr('data-checked') === 'true' )
      $("#source_location_column").prop( "checked", $('#source_location_column_value').attr('data-checked') === 'true' )
      $("#created_column").prop( "checked", $('#created_column_value').attr('data-checked') === 'true' )
      $("#order_payments_column").prop( "checked", $('#order_payments_column_value').attr('data-checked') === 'true' )
      $("#remaining_amount_column").prop( "checked", $('#remaining_amount_column_value').attr('data-checked') === 'true' )
    } });

    $('#export-modal').modal()  
    
    $('#export-modal-button').on('click', () => {
      renderExportFilters()
      $('#export-modal').modal('open')
    })

    if (url.match(/sales_orders($|[#?])/)) $('.sales-orders-listing-tab').addClass('active')
    $('select').formSelect();

    $("#orders_per_page_select").on('change', function() {
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'orders',
                         columnsSelected: selectedColumns(),
                         activeFilters: activeFilters(),
                         dates: selectedDates(),
                         sorting: sorting(),
                         activeLocations: getActiveLocations() })
    });

    $("#filter-button").on('click', function() {
      const url = window.location.href;
      if (url.match(/sales_orders_report($|[#?])/)) calculateReport()
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'orders',
                         columnsSelected: selectedColumns(),
                         activeFilters: activeFilters(),
                         dates: selectedDates(),
                         sorting: sorting(),
                         activeLocations: getActiveLocations() })
      $('#open_orders_filter_value').attr('data-checked', $("#open_orders_filter").is(':checked'));
      $('#pending_orders_filter_value').attr('data-checked', $("#pending_orders_filter").is(':checked'));
      $('#cancelled_orders_filter_value').attr('data-checked', $("#cancelled_orders_filter").is(':checked'));
      $('#closed_orders_filter_value').attr('data-checked', $("#closed_orders_filter").is(':checked'));
      $("#filter-modal").modal("close");
    });

    $("#clear-filter-button").on('click', function() {
      $('#open_orders_filter_value').attr('data-checked', false);
      $('#pending_orders_filter_value').attr('data-checked', false);
      $('#cancelled_orders_filter_value').attr('data-checked', false);
      $('#closed_orders_filter_value').attr('data-checked', false);

      $("#open_orders_filter").prop( "checked", false )
      $("#pending_orders_filter").prop( "checked",  false )
      $("#cancelled_orders_filter").prop( "checked",  false )
      $("#closed_orders_filter").prop( "checked", false )

      if (url.match(/sales_orders_report($|[#?])/)) calculateReport()
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'orders',
                         columnsSelected: selectedColumns(),
                         activeFilters: [],
                         dates: selectedDates(),
                         sorting: sorting(),
                         activeLocations: getActiveLocations() })
      $("#filter-modal").modal("close");
    });

    $('#select-columns-button').on('click', () => {
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'orders',
                         columnsSelected: selectedColumns(),
                         activeFilters: activeFilters(),
                         dates: selectedDates(),
                         sorting: sorting(),
                         activeLocations: getActiveLocations() })
      $('#status_column_value').attr('data-checked', $('#status_column').is(':checked'))
      $('#customer_column_value').attr('data-checked', $('#customer_column').is(':checked'))
      $('#customer_column_type_value').attr('data-checked', $('#customer_column_type').is(':checked'))
      $('#total_qty_column_value').attr('data-checked', $('#total_qty_column').is(':checked'))
      $('#invoiced_qty_column_value').attr('data-checked', $('#invoiced_qty_column').is(':checked'))
      $('#shipping_method_column_value').attr('data-checked', $('#shipping_method_column').is(':checked'))
      $('#total_column_value').attr('data-checked', $('#total_column').is(':checked'))
      $('#deposit_amount_column_value').attr('data-checked', $('#deposit_amount_column').is(':checked'))
      $('#source_location_column_value').attr('data-checked', $('#source_location_column').is(':checked'))
      $('#created_column_value').attr('data-checked', $('#created_column').is(':checked'))
      $('#order_payments_column_value').attr('data-checked', $('#order_payments_column').is(':checked'))
      $('#remaining_amount_column_value').attr('data-checked', $('#remaining_amount_column').is(':checked'))

      $('#select-columns-modal').modal('close');
    })

    $('#clear-columns-button').on('click', () => {
      renderPagination({ numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                         dataSource: 'orders',
                         columnsSelected: [],
                         activeFilters: activeFilters(),
                         dates: selectedDates(),
                         sorting: sorting(),
                         activeLocations: getActiveLocations() })
      $('#status_column_value').attr('data-checked', false)
      $('#customer_column_value').attr('data-checked', false)
      $('#customer_column_type_value').attr('data-checked', false)
      $('#total_qty_column_value').attr('data-checked', false)
      $('#invoiced_qty_column_value').attr('data-checked', false)
      $('#shipping_method_column_value').attr('data-checked', false)
      $('#total_column_value').attr('data-checked', false)
      $('#deposit_amount_column_value').attr('data-checked', false)
      $('#source_location_column_value').attr('data-checked', false)
      $('#created_column_value').attr('data-checked', false)
      $('#order_payments_column_value').attr('data-checked', false)
      $('#remaining_amount_column_value').attr('data-checked', false)

      $('#select-columns-modal').modal('close');
    })

    $('#export-button').on('click', () => {
      let report = (url.match(/sales_orders_report($|[#?])/)) ? true : false
      exportToCsv('/export_sales_orders', {selectedColumns:selectedColumns(),
                                           activeFilters: activeFilters(),
                                           selectedDates: selectedDates(),
                                           sorting: sorting(),
                                           activeLocations: getActiveLocations(),
                                           report: report}
      )
    })

    renderPagination({ numberOfPages: 10,
                        dataSource: 'orders',
                        columnsSelected: selectedColumns(),
                        activeFilters: activeFilters(),
                        dates: selectedDates(),
                        sorting: sorting(),
                        activeLocations: getActiveLocations() })
    renderLocationsModal()
  }
})


export const selectedColumns = () => {
  const url = window.location.href;
  let selectedColumns = []
  if($("#status_column").is(':checked')) selectedColumns.push('status')
  if($("#customer_column").is(':checked')) selectedColumns.push('customer')
  if($("#customer_column_type").is(':checked')) selectedColumns.push('customer_type')
  if($("#total_qty_column").is(':checked')) selectedColumns.push('total_qty')
  if($("#invoiced_qty_column").is(':checked')) selectedColumns.push('invoiced_qty')
  if($("#shipping_method_column").is(':checked')) selectedColumns.push('shipping_method')
  if($("#total_column").length > 0 && $("#total_column").is(':checked')) selectedColumns.push('total')
  if($("#deposit_amount_column").length > 0 && $("#deposit_amount_column").is(':checked')) selectedColumns.push('deposit_amount')
  if($("#source_location_column").length > 0 && $("#source_location_column").is(':checked')) selectedColumns.push('source_location')
  if($("#created_column").is(':checked')) selectedColumns.push('created')
  if($("#order_payments_column").length > 0 && $("#order_payments_column").is(':checked')) selectedColumns.push('order_payments')
  if($("#remaining_amount_column").length > 0 && $("#remaining_amount_column").is(':checked')) selectedColumns.push('remaining_amount')
  if (url.match(/sales_orders_report($|[#?])/) && selectedColumns.length > 0){
    selectedColumns.push('total')
    selectedColumns.push('source_location')
    selectedColumns.push('deposit_amount')
    selectedColumns.push('order_payments')
    selectedColumns.push('remaining_amount')
  }
  return selectedColumns
}

export const activeFilters = () => {
  let active_filters = []
  if($("#open_orders_filter").is(':checked')) active_filters.push('open')
  if($("#pending_orders_filter").is(':checked')) active_filters.push('pending')
  if($("#cancelled_orders_filter").is(':checked')) active_filters.push('canceled')
  if($("#closed_orders_filter").is(':checked')) active_filters.push('closed')
  return active_filters
}

export const renderPaginationSalesOrdersParams = () => ({numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                                                        dataSource: 'orders',
                                                        columnsSelected: [],
                                                        activeFilters: activeFilters(),
                                                        dates: selectedDates(),
                                                        sorting: sorting(),
                                                        activeLocations: getActiveLocations() })

export const sorting = () => {
  return $('#sorting-value').attr('data-order') || 'desc'
}

export const getActiveLocations = () => {
  let active_locations = []
  $('#filter-locations-values .hidden').each((index, hidden_value) => {
    let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
    if($(`#${checkbox_id}`).is(':checked')) active_locations.push(checkbox_id.replace('_location', ''))
  })
  return active_locations
}

const renderLocationsModal = () => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/locations',
    success:  (response) => {
      const url = window.location.href;
      $('#locations-modal').html(response)
      $('#locations-modal').modal({onCloseEnd: () => {
        $('#filter-locations-values .hidden').each((index, hidden_value) => {
          let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
          $(`#${checkbox_id}`).prop('checked', $(hidden_value).attr('data-checked') === 'true')
        })
      }})
      $('#clear-locations-filter-button').on('click', () => {
        $('#filter-locations-values .hidden').each((index, hidden_value) => {
          $(hidden_value).attr('data-checked', false)
          let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
          $(`#${checkbox_id}`).prop('checked', false)
        })
        renderPagination({numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                          dataSource: 'orders',
                          columnsSelected: selectedColumns(),
                          activeFilters: activeFilters(),
                          dates: selectedDates(),
                          sorting: sorting(),
                          activeLocations: getActiveLocations() })
        if (url.match(/sales_orders_report($|[#?])/)) calculateReport()
        $('#locations-modal').modal('close')
      })
      $('#filter-locations-button').on('click', () => {
        $('#filter-locations-values .hidden').each((index, hidden_value) => {
          let checkbox_id = $(hidden_value).attr('id').replace('_value', '')
          $(hidden_value).attr('data-checked', $(`#${checkbox_id}`).is(':checked'));
        })
        renderPagination({numberOfPages: $("#orders_per_page_select").children("option:selected").val(),
                          dataSource: 'orders',
                          columnsSelected: selectedColumns(),
                          activeFilters: activeFilters(),
                          dates: selectedDates(),
                          sorting: sorting(),
                          activeLocations: getActiveLocations() })
        if (url.match(/sales_orders_report($|[#?])/)) calculateReport()
        $('#locations-modal').modal('close')
      })
    },
  });
}

const renderExportFilters = () => {
  let text = 'The exported file will include'
  let active_locations = []
  let size = 0
  let locations
  $('#filter-locations-form .field span').each((index, hidden_value) => {
    size++
    if ($(hidden_value).prev().is(':checked')) active_locations.push($(hidden_value).text())
  })
  if (active_locations.length == 0 || active_locations.length == size ){
    locations = ''
  } else {
    locations = `from ${arrayToString(active_locations)}`
  }
  let active_filters = activeFilters()
  let filters = 'all'
  let numberOfFilters = $('.filter-values .hidden').length
  if(active_filters.length > 0 && active_filters.length < numberOfFilters){
    filters = arrayToString(active_filters)
  }
  let dates = ''
  let selected_dates = selectedDates()
  if (Object.keys(selected_dates).length > 0)
    dates = `between <span style="white-space:nowrap">${selected_dates['from']}</span> and
              <span style="white-space:nowrap">${selected_dates['until']}</span>`
  $('#filters-for-export').html(`${text} ${filters} sales orders ${dates} ${locations}`)
}
