import {exportToCsv, renderPagination} from '../packs/application.js';

$( document ).on('turbolinks:load', () => {
  const url = window.location.href;
  if (url.match(/inventory_report($|[#?])/) ){
    $('.inventory-report-tab').addClass('active')
    $('#export-modal').modal()
    $('#purchase-orders-modal').modal()
    $('#commited-from-po-modal').modal()
    $('#select-columns-modal').modal({onCloseEnd: () => {
        $("#item_cost_column").prop( "checked", $('#item_cost_column_value').attr('data-checked') === 'true' )
        $("#on_hand_column").prop( "checked", $('#on_hand_column_value').attr('data-checked') === 'true' )
        $("#current_price_column").prop( "checked", $('#current_price_column_value').attr('data-checked') === 'true' )
        $("#original_price_column").prop( "checked", $('#original_price_column_value').attr('data-checked') === 'true' )
        $("#discounted_cost_column").prop( "checked", $('#discounted_cost_column_value').attr('data-checked') === 'true' )
      } })

    $('#clear-columns-button').on('click', () => {
      $('#item_cost_column_value').attr('data-checked', false)
      $('#on_hand_column_value').attr('data-checked', false)
      $('#original_price_column_value').attr('data-checked', false)
      $('#current_price_column_value').attr('data-checked', false)
      $('#discounted_cost_column_value').attr('data-checked', false)
      changeColumns([])
      $('#select-columns-modal').modal('close');
    })

    $('#select-columns-button').on('click', () => {
      changeColumns(selectedColumns())
      $('#item_cost_column_value').attr('data-checked', $('#item_cost_column').is(':checked'))
      $('#on_hand_column_value').attr('data-checked', $('#on_hand_column').is(':checked'))
      $('#current_price_column_value').attr('data-checked', $('#current_price_column').is(':checked'))
      $('#original_price_column_value').attr('data-checked', $('#original_price_column').is(':checked'))
      $('#discounted_cost_column_value').attr('data-checked', $('#discounted_cost_column').is(':checked'))

      $('#select-columns-modal').modal('close');
    })

    $('#export-modal-button').on('click', () => {
      renderExportFilters()
      $('#export-modal').modal('open')
    })

    $('#export-button').on('click', () => {
      exportToCsv('/export_inventory', {selectedColumns: selectedColumns()})
    })
    
    $('#inventory-to-pdf').on('click', () => {
      exportToPDF('/inventory_pdf', {selectedColumns: selectedColumns()})
    })

    calculateReport();
  }
})

const renderExportFilters = () => {
  let text = 'The exported file will include the inventory for all items marked as \"Include on inventory report\" on the Heartland Page.'
  $('#filters-for-export').html(`${text}`)
}

const calculateReport = () => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/calculate_inventory_report',
    data: { columns: selectedColumns()},
    beforeSend: () => {
      $('#loader').show()
    },
    success:  (response) => {
      $('#data-container').html(response)
      $('#loader').hide()
      addActionToModalOpener()
    },
    error: () => {
      M.toast({html: 'An error has occurred, please reload the page.'})
      $('#loader').hide()
    }
  });
}

const selectedColumns = () => {
  let selectedColumns = []
  if($("#item_cost_column").is(':checked')) selectedColumns.push('item_cost')
  if($("#on_hand_column").is(':checked')) selectedColumns.push('on_hand')
  if($("#current_price_column").is(':checked')) selectedColumns.push('current_price')
  if($("#original_price_column").is(':checked')) selectedColumns.push('original_price')
  if($("#discounted_cost_column").is(':checked')) selectedColumns.push('discounted_cost')
  return selectedColumns
}

const changeColumns = (columns) => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/change_columns_for_inventory_report',
    data: { columns: columns},
    beforeSend: () => {
      $('#loader').show()
    },
    success:  (response) => {
      $('#data-container').html(response)
      $('#loader').hide()
      addActionToModalOpener()
    },
    error: () => {
      M.toast({html: 'An error has occurred, please reload the page.'})
      $('#loader').hide()
    }
  });
}

const renderPurchaseOrdersModal = (id, public_id) => {
    $.ajax({
      async: true,
      method: 'get',
      url: '/purchase_orders_for_item',
      beforeSend: () => {
        $('#loader').show()
      },
      data: { item_id: id} ,
      success:  (response) => {
        $('#purchase-orders-modal').html(response)
        $('#loader').hide()
        $('#item_id_for_purchase_orders_modal').html(`<a href="https:\/\/hammertown.retail.heartland.us\/#items\/edit\/${id}" target="_blank">${public_id}</a>`)
        $('#purchase-orders-modal').modal('open')
      },
    });
}

const addActionToModalOpener = () => {
  $('.purchase_orders_modal_trigger').on('click', (event) => {
    renderPurchaseOrdersModal(event.target.id, event.target.getAttribute('data-public-id'))
  })
  $('.commited_from_po_modal').on('click', (event) => {
    renderCommitedFromPOModal(event.target.id, event.target.getAttribute('data-public-id'))
  })
}

const renderCommitedFromPOModal = (id, public_id) => {
  $.ajax({
    async: true,
    method: 'get',
    url: '/commited_from_on_order',
    beforeSend: () => {
      $('#loader').show()
    },
    data: { item_id: id} ,
    success:  (response) => {
      $('#commited-from-po-modal').html(response)
      $('#loader').hide()
      $('#item_id_for_commited_from_po_modal').html(`<a href="https:\/\/hammertown.retail.heartland.us\/#items\/edit\/${id}" target="_blank">${public_id}</a>`)
      $('#commited-from-po-modal').modal('open')
    },
  });

}

const exportToPDF = (url) => {
  $.ajax({
    async: true,
    method: 'get',
    beforeSend: () => {
      $('#loader').show()
    },
    url: url,
    xhrFields: {
      responseType: 'blob'
    },
    data: { columns: selectedColumns()} ,
    success:  (response) => {
      $('#loader').hide()
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(response);
      a.href = url;
      a.download = 'Inventory Report';
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    },
    error: () => {
      M.toast({html: 'An error has occurred.'})
      $('#loader').hide()
    }
  });
}
